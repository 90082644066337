// import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import "./style/scrollbar.css";
import Footer from "./components/common/Footer";
import HomePageRoute from "./components/routes/homePageRoute";
import DatenschutzRoute from "./components/routes/datenschutzRoute";
import ImpressumRoute from "./components/routes/impressumRoute";
import AutomationPageRoute from "./components/routes/automationPageRoute";
import DrawerList from "./components/special/drawerList";
import DevelopmentPageRoute from "./components/routes/softwarePageRoute";
import { useEffect, useState } from "react";
import { Modal } from "react-daisyui";
import { motion } from "framer-motion";
import ReactGA from "react-ga4";
import NotFound from "./components/routes/notFound";
import { DrawerContextCode } from "./components/special/DrawerContext";
import ScrollToHashElement from "./components/common/SmoothScroll";
import LoadingScreen from "./components/common/LoadingScreen";
import AboutPageRoute from "./components/routes/aboutPageRoute";
import AgbRoute from "./components/routes/AgbRoute";
import BlogPageRoute from "./components/routes/blogPageRoute";
import BlogPostPageRoute from "./components/routes/blogPostPageRoute";
import FaqPageRoute from "./components/routes/faqPageRoute";
import axios from "axios";
import ContactPageRoute from "./components/routes/contactPageRoute";
import TagManager from "react-gtm-module";
import smoothscroll from 'smoothscroll-polyfill';

export const RoutePath = {
    HOME: "/",
    AUTOMATION: "/automatisierung",
    SOFTWARE: "/software",
    BLOG: "/blog",
    ABOUT: "/unternehmen",
    CONTACT: "/contact",
    DATENSCHUTZ: "/datenschutz",
    IMPRESSUM: "/impressum",
    AGB: "/agb",
};

export const API = "https://cms.wicode.io";

const GA_TRACKING_ID = "G-2EYM6WWGQB";
const GTM_TRACKING_ID = {
    gtmId : "GTM-59HBL9F"
}

export default function App() {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        TagManager.initialize(GTM_TRACKING_ID);

        if (localStorage.getItem("wicode_homepage_cookie_storage")) {
            enableAnalytics();
        }
        if (localStorage.getItem("wicode_homepage_cookie_storage") === null) {
            setVisible(true);
        }
    }, []);

    const [seo, setseo] = useState()
    useEffect(() => {
        const fetchdata = async () => {
            await axios
                .get(API + "/api/seo?populate=software,homepage.metaImage")
                .then((res) => setseo(res.data.data.attributes))
                .catch((err) => console.log(err));
        };

        fetchdata();
    }, [])

    const handleAcceptAll = () => {
        window.fbq("track", "Button 0");
        localStorage.setItem("wicode_homepage_cookie_storage", true);
        setVisible(!visible);
        enableAnalytics();
    };

    const handleAcceptNecessary = () => {
        setVisible(!visible);
    };

    // enables Google Analytics
    const enableAnalytics = () => {
        ReactGA.initialize(GA_TRACKING_ID);
        TagManager.initialize(GTM_TRACKING_ID);
    };

    const [loading, setLoading] = useState(true);
    smoothscroll.polyfill();

    return (
        <div>
            <DrawerContextCode>
                <main
                    className={
                        "flex flex-col items-center bg-base-100 relative text-accent overflow-hidden font-textfam z-50"
                    }
                >
                {loading ? <LoadingScreen /> : '' }
                    {/* <Header changeTheme={handleTheme}/> */}
                    <div className="w-full overflow-hidden items-center z-40 relative">
                        <DrawerList />
                        <motion.Modal
                            initial={{
                                x: "150%",
                            }}
                            animate={{
                                x: !visible ? "150%" : 0,
                            }}
                            transition={{
                                ease: "linear",
                                duration: 0.3,
                            }}
                            className={`h-fit max-h-full sm:w-[90%] xl:w-1/2 bg-black/90 fixed z-[900] sm:bottom-8 sm:right-8 sm:rounded-lg px-12 py-16 lg:p-32 overflow-y-scroll md:overflow-y-hidden`}
                        >
                            <Modal.Header className="text-2xl lg:text-5xl font-bold text-white underline">
                                Diese Website nutzt Cookies & Google Analytics!
                            </Modal.Header>
                            <Modal.Body className="text-sm md:text-base text-white py-8 lg:py-16 ">
                                <p className="overflow-y-scroll">
                                    Wir verwenden Cookies und andere
                                    Technologien auf unserer Website. Einige von
                                    ihnen sind essenziell, während andere uns
                                    helfen, diese Website und Ihre Erfahrung zu
                                    verbessern. Personenbezogene Daten können
                                    verarbeitet werden (z. B. IP-Adressen), z.
                                    B. für personalisierte Anzeigen und Inhalte
                                    oder Anzeigen- und Inhaltsmessung. Weitere
                                    Informationen über die Verwendung Ihrer
                                    Daten finden Sie in unserer
                                    Datenschutzerklärung. Sie können Ihre
                                    Auswahl jederzeit unter Einstellungen
                                    widerrufen oder anpassen.
                                </p>
                            </Modal.Body>
                            <Modal.Actions className="flex flex-col-reverse justify-center items-center sm:flex-row w-full sm:justify-between">
                                <button
                                    onClick={handleAcceptNecessary}
                                    className="text-gray-500 hover:text-gray-600 mt-6"
                                >
                                    Nur notwendige zulassen
                                </button>
                                <button
                                    onClick={handleAcceptAll}
                                    className="btn btn-primary btn-outline w-48"
                                >
                                    Bestätigen
                                </button>
                            </Modal.Actions>
                        </motion.Modal>
                        <ScrollToHashElement />
                        <Routes>
                            <Route
                                path="/"
                                element={<HomePageRoute page={seo?.homepage} loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                exact
                                path=""
                                element={<HomePageRoute page={seo?.homepage} loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                exact
                                path="/automatisierung"
                                element={<AutomationPageRoute loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                exact
                                path="/software"
                                element={<DevelopmentPageRoute page={seo?.software} loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                exact
                                path="/unternehmen"
                                element={<AboutPageRoute loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                exact
                                path="/blog"
                                element={<BlogPageRoute loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                exact
                                path="/blog/:id/:title"
                                element={<BlogPostPageRoute loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                path="/faq"
                                element={<FaqPageRoute loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                path="/kontakt"
                                element={<ContactPageRoute loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                exact
                                path="/datenschutz"
                                element={<DatenschutzRoute loadingFunc={() => setLoading(false)} />}
                            />
                            <Route
                                exact
                                path="/impressum"
                                element={<ImpressumRoute loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                exact
                                path="/agb"
                                element={<AgbRoute loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                exact
                                path="/404"
                                element={<NotFound loadingFunc={() => setLoading(false)}/>}
                            />
                            <Route
                                path="*"
                                element={<Navigate to="/404" />}
                            />
                            <Route
                                path="/blog/:id/undefined"
                                element={<Navigate to="/404" />}
                            />
                        </Routes>
                    </div>
                    <Footer />
                </main>
            </DrawerContextCode>
        </div>
    );
}
